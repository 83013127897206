import React from 'react'
import { graphql } from 'gatsby'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { PostBody } from '@widgets/Post'

const Datenschutz = ({ data, ...props }) => (
  <Layout {...props}>
    <Seo title={data.contentfulPage.title} />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header={data.contentfulPage.title} />
        <PostBody body={data.contentfulPage.body.childMdx.body} />
      </Main>
    </Stack>
  </Layout>
)

export const data = graphql`
  query datenschutz {
    contentfulPage(title: { eq: "Datenschutz" }) {
      title
      body {
        childMdx {
          body
        }
      }
    }
  }
`

export default Datenschutz
